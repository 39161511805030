<template>
  <div uk-grid>
    <connection-modal />

    <!-- Page Content -->
    <div class="uk-width-expand@m">

      <!-- Top Navigation -->
      <top-nav />

      <!-- Main Content -->
      <div class="content-wrapper" uk-height-viewport="expand: true">
        <router-view />
      </div>
    </div>

    <footer-bar />
  </div>
</template>

<script>
import ConnectionModal from '@/components/modals/ConnectionModal.vue';
import TopNav from '@/components/nav/TopNav.vue';
import FooterBar from '@/components/nav/FooterBar.vue';
import Notification from '@/components/util/Notification';

export default {
  name: 'FullPageLayout',
  components: {
    ConnectionModal,
    TopNav,
    FooterBar,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      notifications: 0
    }
  },
  computed: {
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      if (  this.windowWidth < 1366 ) {
        if ( this.notifications === 0 ) {
          Notification.showNotification(this.$t('Generic.Labels.Warning'), this.$t('Generic.Messages.SupportedWindowWidth'), 'warn', 0);
          const notificationElements = document.querySelectorAll('.uk-notification-message');
          this.notifications = notificationElements?.length;
        }
      }
      else {
        this.notifications = 0;
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.content-wrapper {
  padding: 20px 20px 60px 20px;
  // min-height: 89vh;
}

.uk-notification-close {
  display: block;
  color: var(--white-color);
  right: 5px;
  top: 8px;
  margin-right: 5px;
}

.message {
  right: 10px;
}

.uk-notification-message .uk-grid-divider {
  margin-left: -80px;
}
</style>
